.dialog {
    max-width: 30rem;
    margin: auto;
    position: relative;

    & > .icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 1;
    }
}
:global {
    calendar-view {
        &.narrow-box {
            &,
            & + * {
                max-width: 75rem;
            }
        }
        @media (min-width: 577px) {
            & > header > button.btn {
                width: 5rem;
                height: 5rem;
                border-width: 3px;
                border-radius: 50% !important;
                &:first-of-type {
                    background-color: transparent !important;
                }
            }
            .fa-chevron-left::before {
                content: '上一页';
            }
            .fa-chevron-right::before {
                content: '下一页';
            }
        }
        td:hover {
            background: var(--primary-color) !important;
        }
        @media (max-width: 576px) {
            & > header.d-flex {
                justify-content: center !important;
                & > button {
                    border: none;
                    background: transparent !important;
                    padding: 1rem !important;
                }
            }
            td {
                border: none !important;
                border-radius: 4.5px;
            }
        }
    }
}
