.box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    overflow: hidden;
    background: var(--light-color);
    border-radius: 0.5rem;
    padding: 1rem 3rem;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100rem;
        height: 100rem;
        transform: translate(-50%, -90%);
        border-radius: 50%;
        background: #171717;
    }
    & > img:first-of-type {
        position: relative;
        max-height: 50%;
        border-radius: 0.5rem;
    }
    button {
        outline: none;
    }
}
