.header {
    background-image: url(../../image/office-2.png);
    background-size: cover;
    padding-top: 6rem !important;
    padding-bottom: 3rem !important;
    h1 {
        color: white;
    }
    hr {
        border-top-color: white;
        margin: 4rem auto !important;
    }
}
.text-rows {
    display: flex;
    flex-direction: column;
    textarea {
        flex: 1;
    }
}
