.card {
    min-width: 22%;
    max-width: 22%;
    .image {
        height: 15rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
