.box {
    margin: auto;
    padding: 3rem 0;
    @media (min-width: 73rem) {
        width: 73rem;
    }
}
@media (min-width: 1400px) {
    .header {
        font-size: 1.25rem;
    }
}
.count {
    @media (max-width: 576px) {
        width: 95vw;
    }
    @media (min-width: 769px) {
        transform: translateY(-80%);
    }
    & > li {
        &:not(:first-child) {
            border-left-width: 1px;
            border-left-style: solid;
            border-image: linear-gradient(transparent, gray, transparent) 1 100%;
        }
        @media (max-width: 768px) {
            &:nth-child(3) {
                border-left: none;
            }
        }
        & > strong {
            font-size: 1.25rem;
            &:first-child {
                font-size: 2.5rem;
            }
        }
    }
}
.partner {
    width: 100%;
    height: 5rem;
    object-fit: contain;
    filter: grayscale(1);
    opacity: 0.5;
    transition: 0.25s;
}
